import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import AppRoutes from "./Routes/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import TagManager from 'react-gtm-module'
import Hotjar from '@hotjar/browser';




import { hotjar } from 'react-hotjar';

const siteId = 3714254;
const hotjarVersion = 6;

const tagManagerArgs = {
  gtmId: 'GTM-MM2WVVXN'
}

const App = () => {
  TagManager.initialize(tagManagerArgs)
  /**
   * Hotjar integration
   */
  const siteId = 3714254;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);

  hotjar.initialize(siteId, hotjarVersion)

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/*" element={<AppRoutes />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
    </BrowserRouter>
  );
};

export default App;
