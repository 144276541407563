import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const NotificationMsg = ({ closeToast, toastProps, file }) => (
  <div
    style={{
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      background: '#ffffff',
      borderRadius: '5px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '5px',
      }}
    >
      <div>
        <div style={{ fontWeight: 'bold', textAlign: 'left' }}>FILENAME</div>
        <div style={{ textAlign: 'left', color: 'gray' }}>{file?.name}</div>
      </div>
    </div>
    <div
      style={{
        fontWeight: 'bold',
        fontSize: '1.5em',
        textAlign: 'center',
        marginBottom: '10px',
        color: 'green',
      }}
    >
      Transformation started. You can see status on History tab
    </div>
    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
      <div
        style={{ marginRight: '10px', animation: 'spin 1s linear infinite' }}
      >
        <FontAwesomeIcon icon={faSpinner} spin size="lg" />
      </div>
      <div>WORKING</div>
    </div>
  </div>
);

export default NotificationMsg;
