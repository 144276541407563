import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './EditModal.css';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import api from '../../api';
import { useEffect } from 'react';

const EditModal = (props) => {
  const {
    handleClose,
    show,
    selectedRow: orignalRow,
    setSelectedRow: setOrignalRow,
    data,
    indexEdit,
  } = props;
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  useEffect(() => {
    if (orignalRow && show) {
      setSelectedRow(orignalRow);
    }
  }, [orignalRow]);

  const handleSave = async () => {
    setLoading(true);
    const payload = data;
    if (payload.data?.length > 0 && payload?.data[0]?.tableData) {
      if (Array.isArray(selectedRow?.data)) {
        payload.data[0].tableData[indexEdit] = selectedRow?.data;
      } else if (typeof selectedRow?.data === 'object') {
        payload.convertedData[indexEdit] = selectedRow?.data;
      }
      try {
        let res = await api
          .put(`/conversion/updateConversion/${data?.conversion}`, {
            ...payload,
            rowToEdit:
              typeof selectedRow?.data === 'object' ? indexEdit : undefined,
          })
          .then((res) => {
            setOrignalRow(selectedRow);
            handleClose();
            setLoading(false);
          });
      } catch (e) {
        setLoading(false);
      }
    }
  };
  const requiredFields = () => {
    if (Array.isArray(selectedRow?.data)) {
      return selectedRow?.header?.some((header, index) => {
        if (header !== 'Date/Time' && header !== 'FileName') {
          if (!selectedRow?.data?.[index]) {
            return true;
          }
          return false;
        }
        return false;
      });
    }
    return false;
  };

  return (
    <Modal show={show} size={'lg'} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="conversionTitle" style={{ fontSize: '24px' }}>
          Edit
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex w-full flex-wrap
          justify-content-between
        "
          style={{
            flexDirection: width > 552 ? 'row' : 'column',
          }}
        >
          {selectedRow?.header?.map((val, i) =>
            Array.isArray(selectedRow?.data) &&
            selectedRow !== null &&
            val !== 'Date/Time' &&
            val !== 'FileName' ? (
              <div
                className={`d-flex flex-column mt-1`}
                style={{
                  width: width > 552 ? '48%' : '100%',
                }}
              >
                <label className="color-green">{selectedRow?.header[i]}</label>
                <textarea
                  className="p-2 rounded input"
                  style={{
                    width: '',
                  }}
                  type="text"
                  value={selectedRow?.data?.[i] || ''}
                  onChange={(e) => {
                    const data2 = JSON.parse(JSON.stringify(selectedRow));
                    data2.data[i] = e.target.value;
                    setSelectedRow(data2);
                  }}
                ></textarea>
              </div>
            ) : typeof selectedRow?.data === 'object' &&
              selectedRow !== null &&
              val !== 'Date/Time' &&
              val !== 'FileName' ? (
              <div
                className={`d-flex flex-column mt-1`}
                style={{
                  width: width > 552 ? '48%' : '100%',
                }}
              >
                <label className="color-green">{selectedRow?.header[i]}</label>
                <textarea
                  className="p-2 rounded input"
                  style={{
                    width: '',
                  }}
                  type="text"
                  value={selectedRow?.data?.[val] || ''}
                  onChange={(e) => {
                    const data2 = JSON.parse(JSON.stringify(selectedRow));
                    data2.data[val] = e.target.value;
                    setSelectedRow(data2);
                  }}
                ></textarea>
              </div>
            ) : null,
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-save border-0"
          disabled={loading || requiredFields()}
          onClick={handleSave}
        >
          Save
          {loading && (
            <Spinner
              size="sm"
              animation="border"
              variant="white"
              className="ms-2"
            />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
