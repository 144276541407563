import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Signin from '../pages/Signin/signin';
import Signup from '../pages/Signup/signup';
import Home from '../pages/Home/Home';
import EnterEmail from '../pages/EnterEmail/EnterEmail';
import ResetPassword from '../pages/ResetPassword/ResetPassword';

import { OpenRoute, ProtectedAuthRoute, ProtectedRoute } from './protectedRoutes';
import DoneSubscribe from '../pages/DoneSubcribe/DoneSubscribe';
import Cancel from '../pages/Cancel/Cancel';
import Logout from '../pages/Logout/Logout';
import VerifyUser from '../pages/Signup/VerifyUser';
import Profile from '../pages/Profile';
import NewConversion from '../pages/NewConversion';
import Integrations from '../pages/Integration'

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/logout" element={<Logout />} />
      <Route
        exact
        path="/register"
        element={
          <ProtectedRoute>
            <Signup />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/verify"
        element={
          <ProtectedRoute>
            <VerifyUser />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/forgot-password"
        element={
          <ProtectedRoute>
            <EnterEmail />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/reset-password"
        element={
          <OpenRoute>
            <ResetPassword />
          </OpenRoute>
        }
      />
      <Route
        exact
        path="/cancel"
        element={
          <ProtectedAuthRoute>
            <Cancel />
          </ProtectedAuthRoute>
        }
      />
      <Route
        exact
        path="/done-subscribe"
        element={
          <ProtectedAuthRoute>
            <DoneSubscribe />
          </ProtectedAuthRoute>
        }
      />
      <Route
        exact
        path="/signin"
        element={
          <ProtectedRoute>
            <Signin />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path="/"
        element={
          <ProtectedAuthRoute>
            <Home />
          </ProtectedAuthRoute>
        }
      />
      <Route
        exact
        path="/new-conversion"
        element={
          <OpenRoute>
            <NewConversion />
          </OpenRoute>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <ProtectedAuthRoute>
            <Profile />
          </ProtectedAuthRoute>
        }
      />
      <Route
        exact
        path="/integrations"
        element={
          <ProtectedAuthRoute>
            <Integrations />
          </ProtectedAuthRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
