export const plans = [
  {
    id: 1,
    title: "FREE",
    price: "",
    btn_title: "Free plan",
    btn_color: "#1eee",
    d_1: "20 transformations per month",
    d_2: "Standart file size (Max 3Mb file/webpage size)",
    d_3: "Standart URLs processing (3 URLs per batch)",
  },
  {
    id: 2,
    title: "PLUS",
    price: "USD $19/mo",
    btn_title: "Subscribe",
    btn_color: "#1eee",
    d_1: "100 transformations per month",
    d_2: "Extended file size (Max 10Mb file/webpage size)",
    d_3: "Extended URLs processing (10 URLs per batch, autopagination and autoscrolling)",
  },
  {
    id: 3,
    title: "ENTERPRISE",
    price: "",
    btn_title: "Contact Us",
    btn_color: "#1eee",
    d_1: "More transformations, larger files/webpages",
    d_2: "Full website transformation (scan and scrape from entire Website)",
    d_3: "API, Scheduler, More Integrations",
  },
];
