import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import api from '../../api';
import { Header } from '../../components/header';
import Sidebar from '../../components/sidebar';
import { ListContext } from '../../context/list';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import style from './style.module.css';

const Profile = () => {
  const { width } = useWindowDimensions();
  const {
    list,
    setListItems,
    openSideBar,
    setOpenSideBar,
    setFetchConversions,
  } = useContext(ListContext);
  const { email } = JSON.parse(localStorage.getItem('user')) ?? {};

  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [user, setUser] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.post('/user/forgot-password', {
        email,
        changePassword: true,
      });
      if (res.data.message) {
        toast(res.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast(error?.response?.data?.message, { type: 'error' });
    }
  };

  const updateProfile = async ({ phoneNumber, name }) => {
    setProfileLoading(true);
    try {
      await api
        .patch(`/user/update-profile`, { phoneNumber, name })
        .then((res) => {
          if (res.data.message) {
            toast(res.data.message);
            setProfileLoading(false);
            getUserPlan();
          }
        });
    } catch (error) {
      setIsLoading(false);
      toast(error?.response?.data?.message, { type: 'error' });
    }
  };

  const getUserPlan = async () => {
    setPlanLoading(true);
    await api
      .get(`/user/me`)
      .then((res) => {
        setPlanLoading(false);
        setName(res?.data?.name);
        setPhoneNumber(res?.data?.phoneNumber);
        setUser(res?.data);
      })
      .catch((err) => {
        setPlanLoading(false);

        console.log('err in me ', err);
      });
  };

  useEffect(() => {
    !planLoading && !user && getUserPlan();
  }, []);

  return (
    <>
      <Header />
      <div className="main w-100">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className={style.body}>
          <h3 className={style.heading}>Profile</h3>
          {email && (
            <div className={style.row}>
              <h5 className={style.title}>Email</h5>
              <p className={style.value}>{email}</p>
            </div>
          )}

          <div className={style.row}>
            <h5 className={style.title}>Name</h5>
            <div className={style.div}>
              <input
                className={style.input}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => {
                  e.target.value !== user?.name &&
                    updateProfile({ name: e.target.value });
                }}
                disabled={profileLoading || planLoading}
              />
            </div>
          </div>

          <div className={style.row}>
            <h5 className={style.title}>Phone Number</h5>
            <div className={style.div}>
              <PhoneInput
                defaultCountry="US"
                className={style.input}
                style={{
                  borderColor:
                    phoneNumber && !isValidPhoneNumber(phoneNumber) && 'red',
                }}
                placeholder="Enter phone number"
                onBlur={() => {
                  if (
                    phoneNumber &&
                    isValidPhoneNumber(phoneNumber) &&
                    phoneNumber !== user?.phoneNumber
                  ) {
                    updateProfile({ phoneNumber });
                  } else if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
                    toast('Phone Number is invalid', { type: 'warning' });
                  } else if (!phoneNumber) {
                    updateProfile({ phoneNumber: '' });
                  }
                }}
                disabled={profileLoading || planLoading}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e)}
              />
            </div>
          </div>

          <div className={style.row}>
            <h5 className={style.title}>Password</h5>
            <div className={style.div}>
              {user?.googleVerified ? (
                <p className={style.value}>"Google authentication"</p>
              ) : (
                <button className={style.button} onClick={onSubmit}>
                  Change Password
                  {isLoading && (
                    <Spinner
                      className="ms-2"
                      size="sm"
                      animation="border"
                      variant="white"
                    />
                  )}
                </button>
              )}
            </div>
          </div>
          <h3 className={style.heading}>Plans</h3>

          {user?.allPlans?.length > 0 ? (
            <>
              <div className={style.table}>
                <thead>
                  <th>Type</th>
                  <th>Max Allowed File Size</th>
                  <th>URLs limit with "Process URLs"</th>
                  <th>Expiry</th>
                  <th>Expiry Date</th>
                </thead>
                {user?.allPlans?.map((plan, index) => (
                  <tbody key={index}>
                    <td>{plan?.name || '-'}</td>
                    <td>{plan?.maxFileSize || '-'}</td>
                    <td>{plan?.urlsLimit || '-'}</td>
                    <td>
                      {plan?.name == 'FREE'
                        ? 'Unlimited'
                        : plan?.expiryDate
                        ? moment(plan?.expiryDate)?.diff(moment(), 'day(s)') < 0
                          ? 'Expired'
                          : `${moment(plan?.expiryDate)?.diff(
                              moment(),
                              'days',
                            )} days left`
                        : '-'}
                    </td>
                    <td>
                      {plan?.name == 'FREE'
                        ? '-'
                        : moment(plan?.expiryDate)?.format('DD-MM-YYYY')}
                    </td>
                  </tbody>
                ))}
              </div>
            </>
          ) : (
            <div className="w-100 text-center bg-white rounded py-3">
              No data
            </div>
          )}

          <h3 className={style.heading}>API keys</h3>
          {user?.apiSubscription?.length > 0 ? (
            <>
              <div className={`${style.table} ${style.min_width}`}>
                <thead>
                  <th>Active</th>
                  <th>GetData API quota per day</th>
                  <th>Data transformation API call quota per day</th>
                  <th>Max requests per minute</th>
                  <th>Max requests per second</th>
                  <th className={style.more_space}>API Key</th>
                </thead>

                {user?.apiSubscription?.map((apiSub, index) => (
                  <tbody key={index}>
                    <td>{apiSub?.API_IS_ACTIVE ? 'Active' : 'InActive'}</td>
                    <td>{apiSub?.API_PER_DAY_QUOTA_GET || '-'}</td>
                    <td>{apiSub?.API_PER_DAY_QUOTA_POST || '-'}</td>
                    <td>{apiSub?.API_REQ_PER_MIN || '-'}</td>
                    <td>{apiSub?.API_REQ_PER_SEC || '-'}</td>
                    <td className={style.more_space}>
                      {apiSub?.API_KEY || '-'}
                    </td>
                  </tbody>
                ))}
              </div>
            </>
          ) : (
            <div className="w-100 text-center bg-white rounded py-3">
              No data
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Profile;
