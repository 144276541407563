import React, { useEffect } from 'react';
import styles from './DoneSubscribe.module.css';
import logo from '../../assets/images/logo.jpg';
import success from '../../assets/images/tick.png';
import { Button, CircularProgress } from '@mui/material';
import api from '../../api';
import { useState } from 'react';
import { toast } from 'react-toastify';

const DoneSubscribe = () => {
  const [priceTag, setPriceTag] = useState(null);
  const updatePaymentStatus = async ({ sessionId }) => {
    try {
      const res = await api.get('/subscription/update-payment-status', {
        params: {
          sessionId,
        },
      });
      if (res.data) {
        // toast('Your package activated successfully', { type: 'success' });
        // handleValidatePlan();
        // setPriceModalShow(false);
        // navigate('/');
      }
    } catch (err) {
      console.log('err', err);
      toast('Your package activated successfully (code 101)', { type: 'success' });
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    setPriceTag(params.get('priceTag'));
    // updatePaymentStatus({ sessionId });
  }, []);

  return (
    <div className={styles.signin_main}>
      <div className={styles.pic_div}>
      <a href="https://www.datatera.ai/" target="_blank"><img src={logo} className={styles.logo} alt="logo" /></a>
      </div>
      <div className={styles.signinUpper}>
        <div className={styles.signin}>
          <div className={styles.success_main}>
            <img src={success} className={styles.success} alt="success" />
          </div>
          <h6 className={styles.login_heading}>Plus USD {priceTag}</h6>
          <p className={styles.explore_future_heading}>
            25 uploads monthly. Extended file upload size. Extended URLs
            processing including secure websites.
          </p>
          <div className={styles.modal_btn}>
            <Button variant="secondary" className={styles.btn} href="/">
              Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoneSubscribe;
