import React from 'react';
import { Modal } from 'react-bootstrap';

const PlanLimitModal = (props) => {
  const {
    handleClose,
    show,
  } = props;
 
  return (
    <Modal show={show} size={'lg'} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="conversionTitle" style={{ fontSize: '24px' }}>
          Limit Reached
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Write us an email to extend your subscription (<a href='mailto:contacts@datatera.ai'>contacts@datatera.ai</a>)
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-save border-0"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanLimitModal;
