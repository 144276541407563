import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const NewConversion = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        // Store query parameters in localStorage
        const queryParamsString = queryParams.toString();
        // localStorage.setItem("queryParams", queryParamsString);
        localStorage.setItem('queryParams', queryParamsString);
        if (localStorage.getItem('user')) {
            if (!queryParamsString) {
                sessionStorage.setItem('new-conversion', true)
            }
            navigate('/');
        } else {
            navigate('/signin');
        }
    }, []);

    return <div className="main w-100 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner
            animation="border"
            variant="secondary"
        />
    </div>
}
export default NewConversion;