import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './AddModal.css';
import useWindowDimensions from '../../utiles/getWindowDimensions';

const AddDataRowModal = (props) => {
  const { show, handleClose, rowHeader, sampleData, handleSave, loading } = props;
  const { width } = useWindowDimensions();

  const [data,setData]= useState([]);

  useEffect(()=>{if(show){setData([])}},[show])

  const requiredFields = () => {
    if(sampleData){
      if(data?.length === rowHeader?.length){
        return rowHeader?.some((header,index)=>{
          if(header !== 'Date/Time' && header !== 'FileName'){
           if(!data?.[index]){
             return true
           }
           return false
        }
        return false
        })
      }
      return true;
    }
    return false
  };

  return (
    <Modal show={show} size={'lg'} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="conversionTitle" style={{ fontSize: '24px' }}>
          Add row to {sampleData ? 'Sample Data' : 'Result Data'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex w-full flex-wrap justify-content-between"
          style={{
            flexDirection: width > 552 ? 'row' : 'column',
          }}
        >
        {rowHeader?.map((field, index) =>
          field !== 'Date/Time' &&
          field !== 'FileName' ? (
              <div
                key={index}
                className={`d-flex flex-column mt-1`}
                style={{
                  width: width > 552 ? '48%' : '100%',
                }}
              >
                <label className="color-green">{field}</label>
                <textarea
                  className="p-2 rounded input"
                  style={{
                    width: '',
                  }}
                  type="text"
                  value={data?.[index]||''}
                  onChange={(e) => {
                    const tempData = [...data]
                    tempData[index]= e.target.value;
                    setData(tempData);
                  }}
                ></textarea>
              </div>
          ) : null
          )}
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-save border-0"
          onClick={()=>handleSave(data)}
          disabled={loading || requiredFields()}
        >
          Add
          {loading && (
            <Spinner
              size="sm"
              animation="border"
              variant="white"
              className="ms-2"
            />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDataRowModal;
